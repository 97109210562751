<template>
  <div class="recordWrap">
    <div class="mainCom w1200 clearfix">
      <div class="mainComL">
        <menuWX></menuWX>
      </div>
      <div class="mainComR">
        <div class="recordBar bgWhite">
          <div class="titleWrap">
            <span class="title">数据查询</span>
            <span class="back" @click="goBack">返回上级</span>
          </div>
          <div class="tagWrap">
            <span v-for="(item, index) in tagList" :key="item.type" :class="{ active: tagActive == item.type - 1 }"
              @click="tagChange(index)">{{ item.name }}</span>
          </div>
          <div class="searchWrap">
            <div class="searchBar">
              <el-form ref="form" :model="searchForm" label-width="100px">
                <el-form-item class="name" label="游戏账号:">
                  <el-input v-model="userInfo.username" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item v-if="tagList[stateIndex].name !== '安全日志'" class="status" label="状态：">
                  <el-select v-if="tagList[stateIndex].type != 8" v-model="searchForm.status" placeholder="请选择状态">
                    <el-option v-for="(item, index) in status" :label="item.label" :value="item.value"
                      :key="item.value"> </el-option>
                    <!-- <el-option v-for="(item1, index) in grantState" :label="item1.label" :value="item1.value" :key="item1.value"> </el-option> -->
                  </el-select>
                  <el-select v-if="tagList[stateIndex].type == 8" v-model="searchForm.status" placeholder="请选择状态">
                    <!-- <el-option v-for="(item, index) in status" :label="item.label" :value="item.value" :key="item.value"> </el-option> -->
                    <el-option v-for="(item1, index) in grantState" :label="item1.label" :value="item1.value"
                      :key="item1.value"> </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="tagList[tagActive].name == '充值记录'" class="orderNum" label="订单号:">
                  <el-input v-model="searchForm.orderSn"></el-input>
                </el-form-item>
                <el-form-item v-if="tagList[tagActive].name == '公告记录'" class="noticeType" label="公告类型:">
                  <el-select v-model="searchForm.noticeType" placeholder="请选择公告类型">
                    <el-option v-for="(item, index) in noticeType" :label="item.label" :value="item.value"
                      :key="item.value"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item class="time" label="时间:">
                  <el-date-picker v-model="time" type="datetimerange" :picker-options="pickerOptions"
                    range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" align="right">
                  </el-date-picker>
                </el-form-item>
              </el-form>
            </div>
            <div class="searchBtn"><el-button type="primary" @click="search">查询</el-button></div>
          </div>
          <div class="tableWrap">
            <el-table :data="tableData" border stripe style="width: 100%">
              <template v-for="(item, index) in tableHead[tagActive]">
                <!-- <el-table-column :resizable="false" :prop="item.prop" :label="item.label" :minWidth="(item.prop == 'orderSn' || item.prop == 'date' )?150:100"></el-table-column> -->
                <el-table-column :resizable="false" :prop="item.prop" :label="item.label"
                  :minWidth="160"></el-table-column>
              </template>
            </el-table>
          </div>
          <div class="pageWrap">
            <el-pagination background layout="total, prev, pager, next, jumper" :current-page="pageNum"
              :page-size="pageSize" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuWX from '@/components/Menu'
import axios from "axios";
import Cookies from "js-cookie";
import eventBus from '@/common/bus.js'

export default {
  name: 'record',
  components: {
    menuWX,
  },
  data() {
    return {
      tagActive: this.$route.query.type ? this.$route.query.type : 0,
      stateIndex: 0,
      tagList: [
        { name: '公告记录', type: 1 },
        { name: '充值记录', type: 2 },
        { name: '消费记录', type: 3 },
        { name: '划分记录', type: 4 },
        { name: '泡点记录', type: 5 },
        { name: '积分记录', type: 6 },
        { name: '赠予记录', type: 8 },
        { name: '安全日志', type: 7 },
      ],
      status: [
        { label: '全部', value: 0 },
        { label: '成功', value: 1 },
        { label: '失败', value: 2 },
      ],
      grantState: [
        { label: '已完成', value: 1 },
        { label: '已拒收', value: 2 },
        { label: '待收取', value: 3 },
      ],
      noticeType: [
        { label: '普通公告', value: 1 },
        { label: '超级公告', value: 2 },
        { label: '霸屏公告', value: 3 },
        { label: '特权公告', value: 4 },
      ],
      pickerOptions: {
        shortcuts: [
          {
            text: '最近一周',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近一个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          },
          {
            text: '最近三个月',
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }
        ]
      },
      time: '',
      searchForm: {
        endTime: '',       // 结束时间
        orderSn: '',       // 订单号
        startTime: '',     // 开始时间
        status: 0,         // 状态，0：所有，1：成功，2：失败
      },
      pageNum: 1,
      pageSize: 20,
      total: 0,
      tableHead: [
        [
          { prop: 'noticeType', label: '公告类型' },
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'num', label: '发送数量' },
          { prop: 'status', label: '状态' },
        ],                // 公告
        [
          { prop: 'orderSn', label: '订单号' },
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'money', label: '金额' },
          { prop: 'status', label: '状态' },
        ],                // 充值
        [
          { prop: 'itemName', label: '道具名称' },
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'money', label: '金额（白金币）' },
          { prop: 'status', label: '状态' },
        ],                // 消费
        [
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'money', label: '金额（白金币）' },
          { prop: 'status', label: '状态' },
        ],                // 划分
        [
          { prop: 'itemName', label: '道具名称' },
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'flag', label: '获得/消耗' },
          { prop: 'money', label: '泡点' },
          { prop: 'status', label: '状态' },
        ],                // 泡点
        [
          { prop: 'itemName', label: '道具名称' },
          { prop: 'date', label: '时间' },
          { prop: 'username', label: '账号' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'money', label: '积分' },
          { prop: 'status', label: '状态' },
        ],               // 积分
        [
          { prop: 'username', label: '账号' },
          { prop: 'date', label: '登录时间' },
          { prop: 'address', label: '登陆地' },
          { prop: 'source', label: '登录平台' },
          { prop: 'ip', label: '登录IP' },
        ],                // 安全
        [
          { prop: 'itemName', label: '道具名称' },
          { prop: 'date', label: '时间' },
          { prop: 'targetUsername', label: '赠与账号' },
          { prop: 'num', label: '赠予数量' },
          { prop: 'gameArea', label: '游戏大区' },
          { prop: 'status', label: '状态' },
        ],                // 赠予
      ],
      tableData: [],
      userInfo: {
        verifyInfo: {},
        vipInfo: {},
        wallteInfo: {},
      },            // 用户信息
    }
  },
  created() {
    let _this = this
    eventBus.$on('Record', function (data) {
      _this.tagChange(data)
    })
  },
  mounted() {
    document.documentElement.scrollTop = 0
    this.userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.getRecordData(this.tagActive)
  },
  methods: {
    ZeroFn(num) {
      if (num < 10) {
        return '0' + num
      } else {
        return num
      }
    },
    formatTime(time) {
      let y = this.ZeroFn(time.getFullYear())
      let mo = this.ZeroFn(time.getMonth() + 1)
      let d = this.ZeroFn(time.getDate())
      let h = this.ZeroFn(time.getHours())
      let m = this.ZeroFn(time.getMinutes())
      let s = this.ZeroFn(time.getSeconds())
      return y + '-' + mo + '-' + d + ' ' + h + ':' + m + ':' + s

    },
    search() {
      if (this.time) {
        this.searchForm.startTime = this.formatTime(new Date(this.time[0]))
        this.searchForm.endTime = this.formatTime(new Date(this.time[1]))
      } else {
        this.searchForm.startTime = ''
        this.searchForm.endTime = ''
      }
      this.getRecordData()
    },
    getRecordData() {        // 获取各个记录
      axios({
        method: 'post',
        url: '/record/getRecordList',
        headers: {
          token: Cookies.get('wx_token'),
        },
        data: {
          pageNum: this.pageNum,                 // 页码数
          pageSize: this.pageSize,               // 每页显示条数。默认10条
          queryRequest: this.searchForm,
          type: parseInt(this.tagActive) + 1,      // 记录类型,1：公告，2：充值，3：消费，4：划分，5：泡点，6：积分，7：安全
          platForm: 'web',
          userId: Cookies.get('wx_userId'),      // userId
        },
      }).then((res) => {
        if (res.data.state == 200) {

          this.total = res.data.data.counts
          if (this.tagActive == 0) {       // 公告
            this.tableData = res.data.data.noticeLogResponseList
          }
          else if (this.tagActive == 1) {       // 充值
            this.tableData = res.data.data.rechargeLogOneList
          }
          else if (this.tagActive == 6) {
            this.tableData = res.data.data.loginLogResponseList
          } else if (this.tagActive == 7) { // 赠予
            this.tableData = res.data.data.giftLogResponseList
          } else {
            this.tableData = res.data.data.recordLogList // 积分数据、消费数据、划分数据
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        this.$message.error(err.message)
      })
    },
    goBack() {
      this.$router.go(-1)
    },
    tagChange(index) {
      if (index == 6) {
        this.tagActive = 7
        this.searchForm.status = 1

      } else if (index == 7) {
        this.tagActive = 6
      } else {
        this.tagActive = index
        this.searchForm.status = 0

      }
      this.stateIndex = index

      this.getRecordData()
    },
    handleCurrentChange(val) {
      this.pageNum = val
      this.getRecordData()
    },
  }
}
</script>
<style lang="less">
.recordBar {
  .searchBar {
    .el-form {
      overflow: hidden;

      .el-form-item {
        padding-right: 10px;
        margin-bottom: 15px;
        float: left;
        width: 50%;
        box-sizing: border-box;

        .el-form-item__label {
          font-size: 18px;
        }

        .el-select {
          width: 289px;

          .el-input__inner {
            font-size: 18px;
          }
        }
      }

      .name {
        .el-input__inner {
          border: none;
          border-bottom: 1px dashed #333333;
          border-radius: 0;
          font-size: 18px;
        }

        .is-disabled {
          .el-input__inner {
            background: #ffffff;
            cursor: text;
            color: #333333;
          }
        }
      }

      .time {
        .el-date-editor--datetimerange.el-input__inner {
          width: 290px;
        }

        .el-date-editor {
          .el-range__icon {
            display: none;
          }

          .el-range-input {
            width: 44%;
          }
        }
      }
    }
  }

  .searchBtn {
    .el-button {
      width: 100%;

      span {
        font-size: 16px;
      }
    }
  }

  .tableWrap {
    .el-table--border {
      border: 1px solid #D22190;
    }

    .has-gutter {

      tr,
      th {
        background: #D22190;
      }

      th {
        border-right: none;

        .cell {
          font-size: 22px;
          color: #ffffff;
          line-height: 35px;
          text-align: center;
          white-space: nowrap;
        }
      }
    }

    td {
      text-align: center;

      .cell {
        white-space: nowrap;
      }
    }

    .el-table--striped {
      .el-table__body {
        tr.el-table__row--striped {
          td.el-table__cell {
            background: rgba(210, 33, 144, 0.1);
          }
        }
      }
    }
  }

  .pageWrap {
    .el-pagination {
      text-align: center;
    }
  }
}
</style>
<style lang="less" scoped>
.recordWrap {
  .recordBar {
    padding: 30px;

    .titleWrap {
      position: relative;
      z-index: 1;
      padding-bottom: 10px;
      margin-bottom: 20px;
      width: 100%;
      text-align: center;
      border-bottom: 1px solid #333333;
      box-sizing: border-box;

      .title {
        font-size: 30px;
      }

      .back {
        position: absolute;
        right: 10px;
        bottom: 5px;
        z-index: 2;
        font-size: 20px;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    .tagWrap {
      margin-bottom: 25px;
      display: flex;
      justify-content: space-between;

      span {
        padding-bottom: 2px;
        font-size: 24px;
        border-bottom: 4px solid #ffffff;
        cursor: pointer;
      }

      .active {
        font-weight: 600;
        color: #333333;
        border-bottom: 4px solid #D22190;
        cursor: default;
      }
    }

    .searchWrap {
      position: relative;
      z-index: 1;

      .searchBar {
        padding-right: 100px;
        width: 100%;
        box-sizing: border-box;

        .el-form {}
      }

      .searchBtn {
        position: absolute;
        right: 0;
        bottom: 15px;
        z-index: 1;
        width: 80px;
      }
    }

    .pageWrap {
      padding-top: 13px;
    }
  }
}
</style>
